<template>
  <v-data-table
    :headers="headers"
    :items="organismGrowthRecords"
    class="elevation-1"
    hide-actions
  >
    <template v-slot:items="props">
      <td><router-link :to="{ name: insightsRoute, query: { genome_name: props.item.genome_name } }">{{ props.item.genome_name }}</router-link></td>
      <td>{{ props.item.count }}</td>
      <td>
        <v-chip
          v-if="props.item.implicated_labs"
          class="ml-3"
          color="blue lighten-4"
        >
        {{ props.item.implicated_labs }} lab(s)
        </v-chip>
      </td>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: {
    organismGrowthRecords: Array,
    insightsRoute: String,
  },
});

@Component
export default class ClusterOrganismGrowthTable extends ComponentProps {
  private headers = [
    {
      text: 'Genome',
      align: 'left',
      sortable: false,
      value: 'genome_name'
    },
    {
      text: 'Count',
      sortable: false,
      value: 'count',
    },
    {
      text: '',
      sortable: false,
      value: 'implicated_labs',
      width: '1%',
    },
  ]
}
</script>

<style scoped>
</style>
