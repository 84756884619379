<template>
  <div class="pa-4">
    <div class="headline">
      Cluster Intelligence
    </div>
    <div class="font-weight-bold mt-5">Filters</div>
    <v-layout row align-center wrap class="mb-5">
      <v-flex xs6>
        <v-layout row align-center>
          <v-flex shrink>Time:</v-flex>
          <v-flex grow class="pl-4 pr-5">
            <v-select
              single-line
              hide-details
              :items="timePeriods"
              v-model="timePeriod"
              class="filter-select pr-5"
              @change="paramsUpdated"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs6>
        <v-layout row align-center>
          <v-flex shrink>Lab:</v-flex>
          <v-flex grow class="pl-4 pl-5">
            <v-select
              single-line
              hide-details
              multiple
              :items="labs"
              v-model="labIds"
              class="filter-select pr-5"
              @change="paramsUpdated"
            >
            </v-select>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout row align-center>
          <v-flex shrink>Cluster Cutoff (Alleles):</v-flex>
          <v-flex grow class="pl-4 pr-5">
            <v-slider
              v-model="addressBreakpointIdx"
              @change="paramsUpdated"
              :tick-labels="addressBreakpointLabels"
              :max="addressBreakpointLabels.length-1"
              ticks="always"
            ></v-slider>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 md7 class="pr-2">
        <div class="font-weight-bold mb-3">Fastest Growing Clusters</div>
        <ClusterGrowthTable insightsRoute="explore" :clusterGrowthRecords="clusterGrowthRecords"></ClusterGrowthTable>
      </v-flex>
      <v-flex xs12 md5 class="pl-2">
        <div class="font-weight-bold mb-3">Fastest Growing Organisms</div>
        <ClusterOrganismGrowthTable insightsRoute="explore" :organismGrowthRecords="organismGrowthRecords"></ClusterOrganismGrowthTable>
      </v-flex>
    </v-layout>
    <div class="font-weight-bold mt-5 mb-3">Most Recent Samples</div>
    <ClusterInsightsTable insightsRoute="explore" :insightsRecords="insightsRecords"></ClusterInsightsTable>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { Store } from "vuex";
import { components, operations, paths } from "@bugseq-site/app/src/lib/api/api";
import { ComboboxField } from "@bugseq-site/app/src/lib/types";
import { debounce } from "@bugseq-site/shared/src/lib/utils";
import {
  dispatchGetClusters,
  dispatchGetLabMembership,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { readLabs } from "@bugseq-site/app/src/store/modules/api/getters";
import ClusterGrowthTable from "@bugseq-site/app/src/components/app/ClusterGrowthTable.vue";
import ClusterOrganismGrowthTable from "@bugseq-site/app/src/components/app/ClusterOrganismGrowthTable.vue";
import ClusterInsightsTable from "@bugseq-site/app/src/components/app/ClusterInsightsTable.vue";

@Component({
  components: {
    ClusterGrowthTable,
    ClusterOrganismGrowthTable,
    ClusterInsightsTable,
  }
})
export default class ClusterExplorer extends Vue {
  private clusterGrowthRecords: components['schemas']['ClusterGrowthRecord'][] = []
  private organismGrowthRecords: components['schemas']['OrganismGrowthRecord'][] = []
  private insightsRecords: components['schemas']['InsightsRecordResponse'][] = []

  private timePeriods: ComboboxField<number>[] = [
    { text: "30 days", value: 30 },
    { text: "90 days", value: 90 },
    { text: "180 days", value: 180 },
    { text: "1 year", value: 365 },
  ]
  private timePeriod: number = this.timePeriods[this.timePeriods.length-2].value

  private labIds: string[] = []

  public async mounted() {
    dispatchGetLabMembership(this.$store)
    this.paramsUpdated()
  }

  private get labs(): ComboboxField<string>[] {
    return readLabs(this.$store).map(l => ({ text: l.name, value: l.id }))
  }

  private addressBreakpoints: components['schemas']['ClusterAddressBreakpoint'][] = [
    1000,
    200,
    100,
    50,
    20,
    10,
    5,
  ]

  private addressBreakpointLabels: string[] = this.addressBreakpoints.map(ab => `${ab}`)

  private addressBreakpointIdx: number = this.addressBreakpointLabels.length - 1

  @debounce(300)
  private async paramsUpdated() {
    const addressBreakpoint = this.addressBreakpoints[this.addressBreakpointIdx]

    const opts: operations["clusters_v1_explore_clusters_get"]["parameters"]["query"] = {
      address_breakpoint: addressBreakpoint,
      days: this.timePeriod,
    }
    if (this.labIds.length > 0) {
      opts.org_id = this.labIds
    }
    const resp = await dispatchGetClusters(this.$store, opts)
    this.clusterGrowthRecords = resp!.cluster_growth_records
    this.organismGrowthRecords = resp!.organism_growth_records
    this.insightsRecords = resp!.most_recent_insights_records
  }
}
</script>

<style scoped>
.filter-select {
  padding-bottom: 12px;
  margin-bottom: 4px;
}
</style>
