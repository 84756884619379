// errors really don't play well with openapi-fetch
// revisit when we upgrade openapi-fetch (last noted Jan 11, 2024 - there is an updated version available)
/* eslint @typescript-eslint/no-throw-literal: 0 */
/* eslint @typescript-eslint/no-non-null-assertion: 0 */
/* eslint @typescript-eslint/no-unnecessary-type-assertion: 0 */
import { ActionContext } from 'vuex'
import { getStoreAccessors } from 'typesafe-vuex'
import { signOut } from 'aws-amplify/auth'
import { RootState } from '../../state'
import { ApiState } from './state'
import { components, paths } from '@bugseq-site/app/src/lib/api/api'
import { getAuthHeaders, CognitoSessionError } from '@bugseq-site/shared/src/lib/api/auth'
import { BugSeqApiError, isRawBugSeqApiError } from '@bugseq-site/shared/src/lib/api/errors'
import { dispatchAddNotification, dispatchRemoveNotification } from '@bugseq-site/app/src/store/modules/notifications/actions'
import {
  commitSetUserProfile,
  commitSetJobResults,
  commitSetLabs,
  commitAddLabInvites,
  commitLabMembershipUpdate
} from './mutations'
import {
  parseBillingAccountSampleCreditResponse,
  parseBillingAccountUserCreditResponse,
  parseJobRunResponse,
  parseJobRunResultsResponse,
  parseUser,
  parseListBasespaceProjectsResponse,
  parseListBasespaceSamplesResponse,
  parseInsightsRecord,
  parseInviteResponse
} from '@bugseq-site/app/src/lib/api/parse'
import { redirectToSignIn } from '@bugseq-site/app/src/lib/api/auth'

type ApiContext = ActionContext<ApiState, RootState>

export const actions = {
  async createOpenUser (
    context: ApiContext,
    payload: components['schemas']['UserCreateRequest']
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/users/open', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'All set!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e // rethrow so the user can fix the form
    }
  },
  async addNotification (context: ApiContext, payload) {
    await dispatchAddNotification(context, payload)
  },
  async getUserProfile (context: ApiContext) {
    try {
      const { data, error } = await context.state.client.GET('/v1/users/me', { headers: await getAuthHeaders() })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUserProfile(context, parseUser(data!))
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getSampleCredits (
    context: ApiContext,
    payload: {
      billingAccountId: string
      params: paths['/v1/billing/{billing_account_id}/credits/sample']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await context.state.client.GET(
        '/v1/billing/{billing_account_id}/credits/sample',
        {
          headers: await getAuthHeaders(),
          params: {
            query: payload.params,
            path: { billing_account_id: payload.billingAccountId }
          }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountSampleCreditResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getUserCredits (
    context: ApiContext,
    payload: {
      billingAccountId: string
      params: paths['/v1/billing/{billing_account_id}/credits/user']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await context.state.client.GET(
        '/v1/billing/{billing_account_id}/credits/user',
        {
          headers: await getAuthHeaders(),
          params: {
            query: payload.params,
            path: { billing_account_id: payload.billingAccountId }
          }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseBillingAccountUserCreditResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async runCascade (
    context: ApiContext,
    payload: components['schemas']['CascadeRunSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting job',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/process/', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Job successfully submitted - Results will be emailed to you.',
        color: 'success'
      })
      await context.state.router.push('/app/main/submitted')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async dropboxSubmit (
    context: ApiContext,
    payload: components['schemas']['DropboxSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/files/dropbox', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Submitted',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getStaticFileLink (
    context: ApiContext,
    payload: { filename: string }
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/files/static', {
        headers: await getAuthHeaders(),
        params: { query: { filename: payload.filename } }
      })

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data!.url
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async updateUserProfile (
    context: ApiContext,
    payload: components['schemas']['UserUpdateRequest']
  ) {
    const loadingNotification = { content: 'saving', showProgress: true }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.PUT('/v1/users/me', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetUserProfile(context, parseUser(data!))
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Profile successfully updated',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async checkLoggedIn (context: ApiContext) {
    const { data, error } = await context.state.client.GET('/v1/users/me', { headers: await getAuthHeaders() })
    if (error !== undefined) {
      if (isRawBugSeqApiError(error)) {
        throw new BugSeqApiError(error.detail)
      }
      throw error
    }
    commitSetUserProfile(context, parseUser(data!))
  },
  async userLogOut (context: ApiContext) {
    await dispatchAddNotification(context, { content: 'Logged out', color: 'success' })
    await signOut() // this will redirect
  },
  async checkApiError (context: ApiContext, payload: any) {
    if ((payload instanceof CognitoSessionError) || (payload instanceof BugSeqApiError && payload.message === 'User not found')) {
      const existingParams = new URLSearchParams(window.location.search)

      // don't clobber redirect if it exists, and multiple of these are racing
      let redirect = existingParams.get('redirect')
      if (redirect === null) {
        redirect = context.state.router.currentRoute.path
      }
      await redirectToSignIn(context.state.router, redirect)
      return
    }
    let errMsg = 'Error'
    if (payload instanceof BugSeqApiError) {
      errMsg += `: ${payload.message}`
    }
    await dispatchAddNotification(context, {
      color: 'error',
      content: errMsg
    })
  },
  async getUserRuns (
    context: ApiContext,
    payload: paths['/v1/jobs/']['get']['parameters']['query']
  ) {
    try {
      let { data, error } = await context.state.client.GET('/v1/jobs/', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      data = data!
      data.job_runs = data.job_runs.map(parseJobRunResponse)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getResults (
    context: ApiContext,
    { jobId, free }: { jobId: string, free: boolean }
  ) {
    const params = { path: { job_id: jobId } }

    try {
      let data, error
      if (free) {
        ({ data, error } = await context.state.client.GET('/v1/academic/process/{job_id}/results', {
          params
        }))
      } else {
        ({ data, error } = await context.state.client.GET('/v1/process/{job_id}/results', {
          headers: await getAuthHeaders(),
          params
        }))
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data = parseJobRunResultsResponse(data)
      commitSetJobResults(context, {
        jobId,
        results: data
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getFileLink (
    context: ApiContext,
    payload: { jobId: string, filename: string, free: boolean }
  ) {
    const params = {
      path: { job_id: payload.jobId },
      query: { filename: payload.filename }
    }

    try {
      let error, data
      if (payload.free) {
        ({ data, error } = await context.state.client.GET('/v1/academic/process/{job_id}/results/download', {
          params
        }))
      } else {
        ({ data, error } = await context.state.client.GET('/v1/process/{job_id}/results/download', {
          headers: await getAuthHeaders(),
          params
        }))
      }

      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
      if (error) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return data.url
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async runCascadeAcademic (
    context: ApiContext,
    payload: components['schemas']['AcademicCascadeRunSubmitRequest']
  ) {
    const loadingNotification = {
      content: 'Submitting job',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/academic/process/', { body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Job successfully submitted - Results will be emailed to you.',
        color: 'success'
      })
      await context.state.router.push('/free/submitted')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async createLab (
    context: ApiContext,
    payload: components['schemas']['OrganizationCreateRequest']
  ) {
    const loadingNotification = {
      content: 'Creating...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.POST('/v1/organization/', { headers: await getAuthHeaders(), body: payload })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Created!',
        color: 'success'
      })
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async updateLab (
    context: ApiContext,
    payload: { id: string, lab: components['schemas']['OrganizationUpdate'] }
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.PUT('/v1/organization/{org_id}', {
        headers: await getAuthHeaders(),
        body: payload.lab,
        params: { path: { org_id: payload.id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async updateLabMembership (
    context: ApiContext,
    payload: { id: string, payload: components['schemas']['OrganizationMembershipUpdate'] }
  ) {
    const loadingNotification = {
      content: 'Updating...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.PUT('/v1/organization/{org_id}/members/me', {
        headers: await getAuthHeaders(),
        body: payload.payload,
        params: { path: { org_id: payload.id } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      commitLabMembershipUpdate(context, {
        labId: payload.id,
        preferences: data!
      })

      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async removeLabMember (
    context: ApiContext,
    payload: { labId: string, userId: string }
  ) {
    const loadingNotification = {
      content: 'Removing...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.DELETE('/v1/organization/{org_id}/members/{user_id}', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId, user_id: payload.userId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Removed!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async clearLabData (
    context: ApiContext,
    payload: { labId: string }
  ) {
    const loadingNotification = {
      content: 'Deleting...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.DELETE('/v1/organization/{org_id}/data', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Deleted!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async deleteLab (
    context: ApiContext,
    payload: { labId: string }
  ) {
    const loadingNotification = {
      content: 'Deleting...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.DELETE('/v1/organization/{org_id}', {
        headers: await getAuthHeaders(),
        params: { path: { org_id: payload.labId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Deleted!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getLabMembership (context: ApiContext) {
    try {
      const { data, error } = await context.state.client.GET('/v1/organization/membership', {
        headers: await getAuthHeaders()
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      commitSetLabs(context, data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getLabInvites (context: ApiContext, labId: string) {
    try {
      const { data, error } = await context.state.client.GET('/v1/organization/{org_id}/invites', {
        headers: await getAuthHeaders(),
        params: {
          path: { org_id: labId }
        }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      data!.items = data!.items.map(parseInviteResponse)
      commitAddLabInvites(context, data!.items)
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async inviteToLab (
    context: ApiContext,
    payload: { organizationId: string, inviteeEmail: string, memberRole: components['schemas']['OrgMemberRole'] }
  ) {
    const loadingNotification = {
      content: 'Inviting...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { data, error } = await context.state.client.POST('/v1/organization/{org_id}/invites', {
        headers: await getAuthHeaders(),
        params: {
          path: { org_id: payload.organizationId }
        },
        body: { invitee_email: payload.inviteeEmail, member_role: payload.memberRole }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Sent!',
        color: 'success'
      })
      commitAddLabInvites(context, [parseInviteResponse(data!)])
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async acceptLabInvite (
    context: ApiContext,
    payload: { inviteId: string }
  ) {
    const loadingNotification = {
      content: 'Joining...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/organization/invites/{invite_id}/accept', {
        headers: await getAuthHeaders(),
        params: { path: { invite_id: payload.inviteId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Joined!',
        color: 'success'
      })

      // joining a lab can update the user (e.g. their region), so refresh the user
      await dispatchGetUserProfile(context)

      // could go to the lab page, but that doesn't seem useful.
      await context.state.router.push('/app/main/history')
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async shareJob (
    context: ApiContext,
    payload: {
      jobId: string
      sharedViaLabId: string
      sharedWithUserId?: string
      sharedWithLabId?: string
    }
  ) {
    const loadingNotification = {
      content: 'Sharing...',
      showProgress: true
    }
    await dispatchAddNotification(context, loadingNotification)
    try {
      const { error } = await context.state.client.POST('/v1/jobs/{job_id}/access', {
        headers: await getAuthHeaders(),
        body: {
          shared_via_organization_id: payload.sharedViaLabId,
          shared_with_user_id: payload.sharedWithUserId,
          shared_with_organization_id: payload.sharedWithLabId
        },
        params: { path: { job_id: payload.jobId } }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      await dispatchRemoveNotification(context, { notification: loadingNotification })
      await dispatchAddNotification(context, {
        content: 'Shared!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getBasespaceProjects (
    context: ApiContext,
    payload: paths['/v1/basespace/projects']['get']['parameters']['query']
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/basespace/projects', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseListBasespaceProjectsResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getBasespaceSamples (
    context: ApiContext,
    payload: {
      projectId: string
      query: paths['/v1/basespace/projects/{project_id}/samples']['get']['parameters']['query']
    }
  ) {
    try {
      const { data, error } = await context.state.client.GET(
        '/v1/basespace/projects/{project_id}/samples',
        {
          headers: await getAuthHeaders(),
          params: { path: { project_id: payload.projectId }, query: payload.query }
        }
      )
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }
      return parseListBasespaceSamplesResponse(data!)
    } catch (e) {
      await dispatchCheckApiError(context, e)
      throw e
    }
  },
  async getInsightsRecords (
    context: ApiContext,
    payload: paths['/v1/explore/summary_v2']['get']['parameters']['query']
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/explore/summary_v2', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      data!.records = data!.records.map(parseInsightsRecord)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getInsightsAutocomplete (
    context: ApiContext,
    payload: paths['/v1/explore/summary_v2/autocomplete']['get']['parameters']['query']
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/explore/summary_v2/autocomplete', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      return data!.items
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async getClusters (
    context: ApiContext,
    payload: paths['/v1/explore/clusters']['get']['parameters']['query']
  ) {
    try {
      const { data, error } = await context.state.client.GET('/v1/explore/clusters', {
        headers: await getAuthHeaders(),
        params: { query: payload }
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      data!.most_recent_insights_records = data!.most_recent_insights_records.map(parseInsightsRecord)
      return data
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  },
  async updateGenomeSummaryIgnore (
    context: ApiContext,
    payload: { jobId: string, payload: components['schemas']['GenomeSummaryIgnoreUpdateRequest'] }
  ) {
    const params = { path: { job_id: payload.jobId } }
    try {
      const { error } = await context.state.client.PUT('/v1/explore/{job_id}/genomesummaryignore', {
        headers: await getAuthHeaders(),
        body: payload.payload,
        params
      })
      if (error !== undefined) {
        if (isRawBugSeqApiError(error)) {
          throw new BugSeqApiError(error.detail)
        }
        throw error
      }

      await dispatchAddNotification(context, {
        content: 'Updated!',
        color: 'success'
      })
    } catch (e) {
      await dispatchCheckApiError(context, e)
    }
  }
}

const { dispatch } = getStoreAccessors<ApiState, RootState>('api')

export const dispatchCheckApiError = dispatch(actions.checkApiError)
export const dispatchCheckLoggedIn = dispatch(actions.checkLoggedIn)
export const dispatchGetUserProfile = dispatch(actions.getUserProfile)
export const dispatchGetSampleCredits = dispatch(actions.getSampleCredits)
export const dispatchGetUserCredits = dispatch(actions.getUserCredits)
export const dispatchCreateOpenUser = dispatch(actions.createOpenUser)
export const dispatchUserLogOut = dispatch(actions.userLogOut)
export const dispatchUpdateUserProfile = dispatch(
  actions.updateUserProfile
)
export const dispatchRunCascade = dispatch(actions.runCascade)
export const dispatchGetUserRuns = dispatch(actions.getUserRuns)
export const dispatchGetResults = dispatch(actions.getResults)
export const dispatchRunCascadeAcademic = dispatch(actions.runCascadeAcademic)
export const dispatchGetFileLink = dispatch(actions.getFileLink)
export const dispatchCreateLab = dispatch(actions.createLab)
export const dispatchUpdateLab = dispatch(actions.updateLab)
export const dispatchUpdateLabMembership = dispatch(actions.updateLabMembership)
export const dispatchRemoveLabMember = dispatch(actions.removeLabMember)
export const dispatchClearLabData = dispatch(actions.clearLabData)
export const dispatchDeleteLab = dispatch(actions.deleteLab)
export const dispatchGetLabMembership = dispatch(
  actions.getLabMembership
)
export const dispatchGetLabInvites = dispatch(
  actions.getLabInvites
)
export const dispatchInviteToLab = dispatch(actions.inviteToLab)
export const dispatchAcceptLabInvite = dispatch(actions.acceptLabInvite)
export const dispatchShareJob = dispatch(actions.shareJob)
export const dispatchGetBasespaceSamples = dispatch(
  actions.getBasespaceSamples
)
export const dispatchGetBasespaceProjects = dispatch(
  actions.getBasespaceProjects
)
export const dispatchGetInsightsRecords = dispatch(
  actions.getInsightsRecords
)
export const dispatchGetInsightsAutocomplete = dispatch(
  actions.getInsightsAutocomplete
)
export const dispatchGetClusters = dispatch(
  actions.getClusters
)
export const dispatchUpdateGenomeSummaryIgnore = dispatch(
  actions.updateGenomeSummaryIgnore
)
export const dispatchDropboxSubmit = dispatch(
  actions.dropboxSubmit
)
export const dispatchGetStaticFileLink = dispatch(
  actions.getStaticFileLink
)
