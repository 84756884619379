<template>
  <v-data-table
    :headers="headers"
    :items="insightsRecords"
    class="elevation-1"
  >
    <template v-slot:items="props">
      <td>
        <router-link
          :to="{ name: 'results', params: { job: props.item.job_run_id } }"
          target="_blank"
        >
          {{ props.item.user_provided_name || props.item.job_run_id }}
        </router-link>
      </td>
      <td>
        <router-link :to="{ name: insightsRoute, query: { sample_name: props.item.sample_name } }">
          {{ props.item.sample_name }}
        </router-link>
      </td>
      <td>
        <router-link :to="{ name: insightsRoute, query: { genome_name: props.item.genome_name } }">
          {{ props.item.genome_name }}
        </router-link>
      </td>
      <td>
        <router-link
          :to="{ name: insightsRoute, query: { genome_name: props.item.genome_name, cluster_address_lab: props.item.cluster_address_lab_sp.join('.') } }"
        >
          {{ formatClusteringAddress(props.item.genome_name, props.item.cluster_address_lab_sp) }}
        </router-link>
      </td>
      <td><TableKV :kv="props.item.user_provided_metadata"></TableKV></td>
      <td>{{ props.item.created.toLocaleDateString() }}</td>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import TableKV from "@bugseq-site/app/src/components/app/TableKV.vue";
import { formatClusteringAddress } from "@bugseq-site/shared/src/lib/utils";

const ComponentProps = Vue.extend({
  props: {
    insightsRecords: Array,
    insightsRoute: String,
  },
});

@Component({
  components: {
    TableKV,
  },
  methods: {
    formatClusteringAddress,
  },
})
export default class ClusterInsightsTable extends ComponentProps {
  private headers = [
    {
      text: 'Analysis',
      align: 'left',
      sortable: false,
      value: 'user_provided_name'
    },
    {
      text: 'Sample',
      align: 'left',
      sortable: false,
      value: 'sample_name'
    },
    {
      text: 'Organism',
      align: 'left',
      sortable: false,
      value: 'genome_name'
    },
    {
      text: 'Cluster',
      sortable: false,
      value: 'cluster_address_lab_sp',
    },
    {
      text: 'Metadata',
      sortable: false,
      value: 'user_provided_metadata',
    },
    {
      text: 'Added',
      sortable: false,
      value: 'created',
    },
  ]
}
</script>

<style scoped>
</style>
