<template>
  <div>
    <v-layout
      row
      v-for="(val, key) in kv"
      class="my-1"
      :key="key"
    >
      <v-flex class="">
        {{ formatKey(key) }}:
      </v-flex>
      <v-flex>
        {{ formatVal(val, key) }}
      </v-flex>
    </v-layout>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

const ComponentProps = Vue.extend({
  props: {
    kv: Object,
  },
});

export default class TableKV extends ComponentProps {
  // TODO make these generic/params/something
  private formatKey(metadataKey: string): string {
    if (metadataKey === "negative_control") {
      return "Negative Control"
    }

    return metadataKey
  }

  private formatVal(metadataVal: string | boolean, metadataKey: string): string {
    // negative_control is the only bugseq-controlled key, and the only one
    // that may be a boolean.
    if (metadataKey === "negative_control") {
      return metadataVal ? "True" : "False"
    }

    if (typeof metadataVal !== "string") {
      throw new Error(`unrecognized value type: ${metadataVal}`)
    }

    return metadataVal
  }
}
</script>
