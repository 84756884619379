export const formatTime = (time: Date | null | undefined): string | null => {
  if (time === null || time === undefined) {
    return null
  }
  return time.toLocaleString()
}

export const formatCurrency = (cents: number | null | undefined): string | null => {
  if (cents === undefined || cents === null) {
    return null
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })

  return formatter.format(cents / 100)
}

export const formatSize = (bytes: number): string => {
  // https://stackoverflow.com/a/14919494
  const thresh = 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh &&
    u < units.length - 1
  )

  return `${bytes.toFixed(1)} ${units[u]}`
}

export const formatDuration = (seconds: number): string => {
  // https://stackoverflow.com/a/25279399
  const date = new Date(0)
  date.setSeconds(seconds)
  return date.toISOString().substring(11, 19)
}

export function formatClusteringAddress (genomeName: string, labAddressSplit: string[] | null): string | null {
  if (labAddressSplit == null) {
    return null
  }

  const prefix = genomeName.split(' ').map((gn, idx) => idx === 0 ? gn[0].toUpperCase() : gn[0].toLowerCase()).join('')
  return prefix + '-' + labAddressSplit.join('.')
}

export const copyToClipboard = async (text: string): Promise<void> => {
  // this returns a promise, maybe a caller would want
  // to action success/error
  //
  // also, typescript has a hard time with navigator.clipboard:
  // https://github.com/Microsoft/TypeScript/issues/26728
  await window.navigator.clipboard.writeText(text)
}

export function debounce (delay: number) {
  return function (target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value
    let timeoutId: number | null

    descriptor.value = function (...args: any[]) {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
      timeoutId = window.setTimeout(() => {
        originalMethod.apply(this, args)
      }, delay)
    }

    return descriptor
  }
}

export function urlSafeBase64Encode (obj: any): string {
  const jsonString = JSON.stringify(obj)
  const base64String = btoa(jsonString)
  const urlSafeString = base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '')
  return urlSafeString
}

export function urlSafeBase64Decode (encodedString: string): any {
  const base64String = encodedString.replace(/-/g, '+').replace(/_/g, '/')
  const paddedBase64String = base64String.padEnd(base64String.length + (4 - base64String.length % 4) % 4, '=')
  const jsonString = atob(paddedBase64String)
  const decodedObject = JSON.parse(jsonString)
  return decodedObject
}
