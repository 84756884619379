<template>
  <ClusterExplorer />
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import ClusterExplorer from "@bugseq-site/app/src/components/app/ClusterExplorer.vue";

@Component({
  components: {
    ClusterExplorer,
  },
  metaInfo: {
    title: "Cluster Intelligence",
  },
})
export default class ClusterExplorerPage extends Vue {}
</script>
