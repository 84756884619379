<template>
  <v-container fluid justify-center d-flex>
    <v-flex class="md12 lg10 xl8 google-font">
      <v-card>
        <div class="pa-4">
          <div class="pt-2 pb-4 headline">
            <span v-if="!archived_publication_analysis">Run a New Analysis</span>
            <span v-else>Archived Publication Analysis</span>
          </div>
          <div v-if="archived_publication_analysis" class="orange lighten-5 mb-4 pa-3 border-radius">
            <v-icon class="mr-2">error</v-icon><span class="font-weight-bold">Note on Archived Publication Analysis</span>
            <div class="mt-3">
              You are accessing our Archived Publication Analysis. It includes components from our publications, continuing our commitment to the scientific community (<a href="/academic">full comparison</a>).
            </div>
            <div class="mt-3">
              For access to BugSeq's complete, supported analysis and pipelines, sign up and receive 3 free trials.
            </div>
            <div class="mt-3">
              <v-btn
                class="text-capitalize mx-0"
                color="primary"
                @click="redirectToSignUp()"
              >Sign Up for Free Trials</v-btn>
            </div>
          </div>
          <div class="pb-3">
            BugSeq will perform an end-to-end analysis, classifying reads
            against our microbial database, detecting antimicrobial resistance
            and more.
          </div>
          <div class="pb-2 pt-4 font-weight-bold">
            Step {{ getStage(Stage.File) }}. Sample Selection
          </div>
          <div class="mt-2 mb-5">
            <v-tabs>
              <v-tab class="text-capitalize google-font" key="upload"
                >Upload</v-tab
              >
              <v-tab class="text-capitalize google-font" key="basespace"
                >Import from BaseSpace</v-tab
              >
              <v-tab-item key="upload" class="mt-4">
                <div class="pb-4">
                  The following file extensions are currently supported:
                  <span class="code">.bam</span>,
                  <span class="code">.fq</span>, and
                  <span class="code">.fastq</span> (plus
                  <span class="code">.gz</span> or
                  <span class="code">.gzip</span> if compressed). Detailed input
                  requirements may be found in the
                  <a href="https://docs.bugseq.com/input/" target="_blank"
                    >BugSeq Docs</a
                  >.
                </div>
                <Dropzone :uploader="uploader" />
              </v-tab-item>
              <v-tab-item key="basespace" class="mt-4">
                <div v-if="basespaceEnabled">
                  <BasespaceSamplePicker :uploader="basespaceSampleUploader" />
                </div>
                <div v-else>
                  <a
                    href="mailto:contact@bugseq.com?subject=Enable Basespace&body=Hi! I'd like to enable Basespace import on my account."
                    >Contact us</a
                  >
                  to enable importing samples directly from Basespace.
                </div>
              </v-tab-item>
            </v-tabs>
          </div>
          <UploadStatusPanel
            name="Uploads"
            :uploader="uploader"
          >
          </UploadStatusPanel>
          <UploadStatusPanel
            name="BaseSpace Uploads"
            :uploader="basespaceSampleUploader"
          >
          </UploadStatusPanel>
          <v-alert
            type="error"
            :value="getFailedUploads().length > 0"
            class="google-font my-4"
          >
            Some files failed to upload. Please remove them from above and retry
            or submit without them.
            <ul class="mt-3">
              <li
                v-for="f in getFailedUploads()
                  .filter((f) => f.err)
                  .slice(0, 5)"
              >
                {{ f.err }}
              </li>
            </ul>
          </v-alert>
          <v-alert
            type="error"
            :value="!!validateUploads()"
            class="google-font my-4"
          >
            {{ validateUploads() }}
          </v-alert>
          <v-alert
            :value="!!uploadWarnings()"
            type="info"
            class="google-font my-4"
          >
            {{ uploadWarnings() }}
          </v-alert>
          <div v-if="!archived_publication_analysis && remainingSampleCreditsByType" class="my-4">
            <div>Remaining samples on plan:</div>
            <ul>
              <li v-for="[sampleType, remainingCount] in remainingSampleCreditsByType" :key="sampleType">{{ remainingCount | formatRemainingSampleCount }} ({{ sampleType }})</li>
            </ul>
            <v-alert
              class="google-font my-4"
              type="error"
              :value="sampleCountRemaining() <= 0"
            >
              You have no remaining samples. <a href="/quote" target="_blank" class="white--text">Fill out a quote request</a> to get more samples.
            </v-alert>
            <v-alert
              type="info"
              :value="sampleCountRemaining() > 0 && sampleCountRemaining() < 5"
              class="google-font my-4"
            >
              <a href="/quote" target="_blank" class="white--text">Fill out a quote request</a> to get more samples.
            </v-alert>
          </div>
          <div v-if="archived_publication_analysis">
            <div class="pb-2 pt-4 font-weight-bold">
              Step {{ getStage(Stage.Email) }}. Email for Results (Must be
              Academic Address)
            </div>
            <v-text-field
              v-model="email"
              v-validate="'required|email|academicEmail'"
              label="Email"
              name="email"
              :error-messages="errors.first('email')"
            />
          </div>
          <div>
            <div class="pb-2 pt-4 font-weight-bold">
              Step {{ getStage(Stage.Info) }}. Analysis Information
            </div>
            Provide information on your samples to improve analysis accuracy.
            <div class="mt-3">
              <b>Name Your Analysis</b> <span class="mx-1">(optional)</span
              ><PopOut icon="help"
                >This name will be included in your results to help you identify
                your analysis.</PopOut
              >
            </div>
            <v-text-field
              label="My favourite analysis"
              v-model="analysisName"
              single-line
            ></v-text-field>
            <div class="mt-3" v-if="labs.length > 0">
              <b>Lab Selection</b><PopOut icon="info">Data submitted will be associated with this lab. If you are a Submitter of multiple labs, you must choose which lab to associate the analysis with.</PopOut>
              <v-select
                name="Lab Association"
                class="combo-box-field"
                :items="labAssociationOptions"
                v-model="labAssociation"
              ></v-select>
            </div>
            <div class="mt-3">
              <b>Platform</b>
            </div>
            <v-select
              name="platform"
              class="combo-box-field"
              :items="platforms"
              v-model="platform"
              v-on:change="selectPlatform"
              v-validate="'required'"
              :error-messages="errors.first('platform')"
              ref="platformCombobox"
              :disabled="getLabEnforcedOptions().platform"
            ></v-select>
            <div v-if="getLabEnforcedOptions().platform" class="caption">
              This option is mandated by the selected lab
            </div>
            <div v-if="kits.has(platform)">
              <div class="mt-3">
                <b>Device & Chemistry</b>
              </div>
              <v-select
                name="kit"
                class="combo-box-field"
                :items="kits.get(platform)"
                v-model="kit"
                ref="kitCombobox"
                v-validate.immediate="'knownKit'"
                :error-messages="errors.first('kit')"
                :disabled="getLabEnforcedOptions().kit"
              ></v-select>
              <div v-if="getLabEnforcedOptions().kit" class="caption">
                This option is mandated by the selected lab
              </div>
            </div>
            <SampleMetadataForm
              :sample-names="sampleNames()"
              :lab-metadata-keys="labMetadataKeys()"
              :rows="metadataRows"
              @add-samples="addMetadataSamples"
              @remove-samples="removeMetadataSamples"
            ></SampleMetadataForm>
            <v-alert
              type="error"
              :value="!!validateMetadata()"
              class="google-font mb-4"
            >
              {{ validateMetadata() }}
            </v-alert>
          </div>
          <div>
            <div class="pb-2 pt-4 font-weight-bold">
              Step {{ getStage(Stage.AdvancedOptions) }}. Advanced Options
            </div>
            <div v-if="archived_publication_analysis">
              Register to unlock features like
              RNA/cDNA analysis<span class="ml-1"
                ><PopOut icon="info"
                  >Recommended for metatranscriptomic, SARS-CoV-2 amplicon and
                  16S analyses for improved classification and variant calling
                  calling</PopOut
                ></span
              >, choice of reference dataset<span class="ml-1"
                ><PopOut icon="info"
                  >Recommended for environmental samples for improved
                  metagenomic classification and binning</PopOut
                ></span
              >
              and analysis tailored to sample type (eg. bacterial isolate
              <PopOut icon="info">Enables improved binning and reporting</PopOut
              >, respiratory, stool, etc).
              <div class=mt-3>
                <v-btn
                  class="text-capitalize mx-0"
                  color="primary"
                  @click="redirectToSignUp()"
                >Sign up for Free Trials</v-btn>
              </div>
            </div>
            <div v-else>
              <div class="mt-3"><b>Metagenomic Database</b></div>
              <v-radio-group
                column
                v-model="metagenomicDatabase"
                class="px-4"
                :disabled="getLabEnforcedOptions().metagenomicDatabase"
              >
                <v-radio
                  color="primary"
                  value="BUGSEQ_DEFAULT"
                >
                  <template v-slot:label>
                    <div>
                      BugRef Curated DB
                      <PopOut icon="help">
                        Select this database for fast and accurate analysis of
                        clinical (human) specimens, bacterial isolates and most other
                        samples.
                      </PopOut>
                    </div>
                  </template>
                </v-radio>
                <v-radio color="primary" value="NCBI_NT">
                  <template v-slot:label>
                    <div>
                      NCBI core_nt
                      <PopOut icon="help"
                        >Select this database for accurate analysis of
                        samples deriving from animal hosts (non-human) or environmental samples with high microbial
                        diversity.</PopOut
                      >
                    </div>
                  </template>
                </v-radio>
              </v-radio-group>
              <div v-if="getLabEnforcedOptions().metagenomicDatabase" class="caption">
                This option is mandated by the selected lab
              </div>
              <div class="mt-3">
                <b>Sample Type</b>
                <PopOut icon="help"
                  >Providing sample type enables BugSeq to provide specialized
                  analysis.</PopOut
                >
              </div>
              <v-select
                class="combo-box-field"
                name="sample type"
                :items="sampleTypes"
                v-model="sampleType"
                v-validate="'required'"
                :error-messages="errors.first('sample type')"
                ref="sampleTypeCombobox"
                :disabled="getLabEnforcedOptions().sampleType"
              ></v-select>
              <div v-if="getLabEnforcedOptions().sampleType" class="caption">
                This option is mandated by the selected lab
              </div>
              <div class="mt-3">
                <b>Sequenced Material</b>
                <PopOut icon="help"
                  >Providing details on the sequenced material improves multiple
                  outputs, including assembly and quality control. If your sample preparation included a reverse transcription step (e.g. SARS-CoV-2, influenza or viral metagenomics), select cDNA/RNA.</PopOut
                >
              </div>
              <v-select
                name="moleculeType"
                class="combo-box-field"
                :items="moleculeTypes"
                v-model="moleculeType"
                :disabled="getLabEnforcedOptions().moleculeType"
              ></v-select>
              <div v-if="getLabEnforcedOptions().moleculeType" class="caption">
                This option is mandated by the selected lab
              </div>
              <div class="my-3">
                <b>Other Options</b>
                <v-checkbox
                  v-model="includeInLabDb"
                  label="Include samples in Outbreak Analysis"
                  hide-details
                >
                  <template slot="label">
                    <span class="google-font black--text">
                      Include samples in Outbreak Analysis (Genomic Relatedness Visualization)
                      <PopOut icon="help"
                        >BugSeq saves all submitted samples from your lab into a
                        database for for outbreak detection 
                        and investigation. To omit the submitted samples from your
                        lab's database and outbreak analysis, uncheck this box.
                        Independent outbreak reports will be generated for each species in the submission.
                        Outbreak analysis method depends on the submitted taxon (e.g. bacteria vs. viruses) but includes our refMLST method (similar to cgMLST) for bacteria and haploid fungi. See our refMLST publication for more details on
                        the method.
                      </PopOut>
                    </span>
                  </template>
                </v-checkbox>
                <v-checkbox
                  v-if="metagenomicDatabase === 'NCBI_NT'"
                  v-model="filterAnimalReads"
                  label="Samples derive from mouse, rat, chicken, cow or pig host"
                  hide-details
                >
                  <template slot="label">
                    <span class="google-font black--text">
                      Filter Animal Reads: Check this box if samples derive from mouse, rat, chicken, cow or pig host
                      <PopOut icon="help"
                        >Enabling this option filters reads from these species before metagenomic classification. Analysis of samples from these hosts is faster and more accurate if this option is selected. Human reads are always filtered from samples before analysis, regardless of this selection. This option is not applicable for the BugRef database as it does not contain animal sequences.
                      </PopOut>
                    </span>
                  </template>
                </v-checkbox>
              </div>
            </div>
          </div>
          <div class="pb-2 pt-4 font-weight-bold">
            Step {{ getStage(Stage.Submit) }}. Submit
          </div>
          <div class="pb-3">
            Once you click submit, your data will immediately begin being analyzed. You will receive an email within a few hours when results are available.
          </div>
          <div class="pb-3 caption">
            By clicking submit, you are agreeing to our
            <a
              href="https://docs.bugseq.com/legal/terms-of-service/"
              target="_blank"
              >Terms of Service</a
            >.
          </div>
          <div style="text-align: center;">
            <v-btn
              large
              @click="submit"
              color="primary"
              :disabled="
                errors.items.length > 0 ||
                getInProgressUploads().length > 0 ||
                getFailedUploads().length > 0 ||
                getSucceededUploads().length === 0 ||
                !!validateUploads() ||
                !!validateMetadata() ||
                submissionInProgress
              "
              class="text-capitalize"
            >
              Submit
            </v-btn>
          </div>
        </div>
      </v-card>
    </v-flex>
  </v-container>
</template>

<script lang="ts">
import Vue from "vue";
import Component from "vue-class-component";
import { Store } from "vuex";
import { readLabs, readUserProfile } from "@bugseq-site/app/src/store/modules/api/getters";
import BasespaceSamplePicker from "@bugseq-site/app/src/components/app/BasespaceSamplePicker.vue";
import Dropzone from "@bugseq-site/app/src/components/app/Dropzone.vue";
import UploadStatusPanel from "@bugseq-site/app/src/components/app/UploadStatusPanel.vue";
import PopOut from "@bugseq-site/shared/src/components/PopOut.vue";
import SampleMetadataForm, { MetadataRow } from "@bugseq-site/app/src/components/app/SampleMetadataForm.vue";
import { Uploader } from "@bugseq-site/app/src/lib/upload";
import {
  ComboboxField,
  moleculeTypes,
  sampleTypes,
  platforms,
  kits,
} from "@bugseq-site/app/src/lib/types";
import { BasespaceSampleUploader } from "@bugseq-site/app/src/lib/basespace";
import {
  dispatchRunCascade,
  dispatchRunCascadeAcademic,
  dispatchGetLabMembership,
  dispatchGetSampleCredits,
} from "@bugseq-site/app/src/store/modules/api/actions";
import { components } from '@bugseq-site/app/src/lib/api/api'
import { getWwwUrl } from '@bugseq-site/shared/src/env'

enum Stage {
  File,
  Email,
  Info,
  AdvancedOptions,
  Submit,
}

interface Upload {
  bugseqId?: string;

  err?: string;
}

const SubmissionFormProps = Vue.extend({
  props: {
    "archived_publication_analysis": {
      type: Boolean,
      default: false,
      required: false,
    }
  }
})

function formatRemainingSampleCount(count: number) {
  if (count > 1000) {
    return "1000+"
  }
  if (count > 100) {
    return "100+"
  }
  if (count === 1) {
    return `${count} sample`
  }
  return `${count} samples`
}

function removeExtensions(filename: string): string {
  const extensions = ['.gz', '.fastq', '.fq', '.fast5', '.bam']
  let done = false
  while (!done) {
    done = true
    for (const ext of extensions) {
      if (filename.endsWith(ext)) {
        filename = filename.slice(0, -ext.length)
        done = false
        break
      }
    }
  }
  return filename
}

function getIlluminaSampleAndLaneId(filename: string): string | null {
  // TODO update link
  // logic matches https://gitlab.com/bugseq/cascade/-/blob/2d207254b8dd118447d483051d8890e5dae1b823/workflows/file_ops.nf#L101-115
  const illuminaFileRegexes = [
    /(.*)_R[1,2]_001\.f[a-z.]+$/,
    /(.*)_R[1,2]\.f[a-z.]+$/,
    /(.*)_[1,2]\.f[a-z.]+$/,
  ]

  for (const regex of illuminaFileRegexes) {
    const match = filename.match(regex);
    if (match) {
      return match[1]; // Return the captured sample name
    }
  }

  return null
}

function getIlluminaSampleId(sampleAndLaneId: string): string {
  // TODO update link
  // logic matches https://gitlab.com/bugseq/cascade/-/blob/2d207254b8dd118447d483051d8890e5dae1b823/workflows/file_ops.nf#L117-121
  const sampleName = sampleAndLaneId.match(/([a-zA-Z0-9_\-\.]+)_S\d+(_L\d+)?/)
  if (sampleName) {
    return sampleName[1];
  }

  return sampleAndLaneId
}

function getIlluminaSampleName(fileName: string): string | null {
  const sampleAndLaneId = getIlluminaSampleAndLaneId(fileName)
  if (!sampleAndLaneId) {
    return null
  }

  return getIlluminaSampleId(sampleAndLaneId)
}

const acceptedExtensions = [
  'fq',
  'fastq',
  'fq.gz',
  'fastq.gz',
  'bam'
]

@Component({
  components: {
    BasespaceSamplePicker,
    Dropzone,
    PopOut,
    SampleMetadataForm,
    UploadStatusPanel,
  },
  data() {
    return {
      Stage,
      moleculeTypes,
      sampleTypes,
      platforms,
      kits,
    };
  },
  filters: {
    formatRemainingSampleCount,
  },
})
export default class SubmissionForm extends SubmissionFormProps {
  private quoteUrl: string = `${getWwwUrl()}/quote`

  public s3Region;
  public email = "";
  public metagenomicDatabase: components["schemas"]["MetagenomicDatabase"] = "BUGSEQ_DEFAULT";
  private metadataRows: MetadataRow[] = [];
  public academicStages: Stage[] = [
    Stage.File,
    Stage.Email,
    Stage.Info,
    Stage.AdvancedOptions,
    Stage.Submit,
  ];
  public stages: Stage[] = [
    Stage.File,
    Stage.Info,
    Stage.AdvancedOptions,
    Stage.Submit,
  ];
  public remainingSampleCreditsByType: Map<string, number> | null = null;
  public analysisName: string | null = null;
  public sampleType: components['schemas']['app__models__job_run_options__SampleType'] | null = null;
  public moleculeType: components["schemas"]["MoleculeType"] = "DNA";
  public platform: components["schemas"]["Platform"] | null = null;
  public kit: components["schemas"]["Kit"] | null = null;
  public includeInLabDb: boolean = true;
  public filterAnimalReads: boolean = false;
  public labAssociation: string = "";

  private uploader: Uploader;
  private basespaceSampleUploader: BasespaceSampleUploader;
  private submissionInProgress = false;

  constructor() {
    super();

    const userProfile = readUserProfile(this.$store);
    if (userProfile && userProfile.pinned_region) {
      this.s3Region = userProfile.pinned_region;
    } else {
      this.s3Region = "ca-central-1";
    }

    if (this.archived_publication_analysis) {
      this.sampleType = "GENERIC";
    }

    this.uploader = new Uploader(
      this.s3Region, // region
      acceptedExtensions,
      this.archived_publication_analysis,
    );

    this.basespaceSampleUploader = new BasespaceSampleUploader();
  }

  private async mounted() {
    if (!this.archived_publication_analysis) {
      const userProfile = readUserProfile(this.$store)!;
      const [labMembership, sampleCredits] = await Promise.all([
        dispatchGetLabMembership(this.$store),
        dispatchGetSampleCredits(
          this.$store,
          {
            billingAccountId: userProfile.billing_account_id,
            params: {
              remaining_count_gt: 0,
              include_expired: false,
            },
          }
        ),
      ])
      this.remainingSampleCreditsByType = sampleCredits!.items.reduce((m, curr) => {
        const sampleTypeNameLookup = {
          metagenomic: "metagenomic",
          sixteen_s: "16S/ITS",
          isolate: "isolates",
          any_sample_type: "any type of sample",
        }
        const sampleType = sampleTypeNameLookup[curr.sample_type]

        if (!m.has(sampleType)) {
          m.set(sampleType, 0)
        }
        m.set(sampleType, m.get(sampleType) + curr.remaining_count)
        return m
      }, new Map())
    }

    const enabledLabs = this.labAssociationOptions.filter(lao => !lao.disabled)
    if (enabledLabs.length > 0) {
      this.setLabAssociation(enabledLabs[0].value)
    }
  }

  public sampleCountRemaining() {
    if (!this.remainingSampleCreditsByType) {
      return false
    }

    const totalSampleCountRemaining = Array.from(this.remainingSampleCreditsByType.values()).reduce((partialSum, a) => partialSum + a, 0)
    return totalSampleCountRemaining
  }

  get basespaceEnabled() {
    const userProfile = readUserProfile(this.$store);
    if (!userProfile) {
      return;
    }
    return userProfile.enabled_features.includes("basespace_api") || userProfile.enabled_features.includes("mock_basespace_api");
  }

  get labs() {
    return readLabs(this.$store);
  }

  get labAssociationOptions() {
    const userProfile = readUserProfile(this.$store);
    if (!userProfile) {
      return [];
    }

    return this.labs
      .map((lab) => ({
        text: lab.name,
        value: lab.id,
        disabled: !lab.members.find((m) => m.id === userProfile.id && m.member_role === "SUBMITTER"),
      }))
  }

  private setLabAssociation(labId: string) {
    this.labAssociation = labId;

    const lab = this.labs.find(l => l.id === labId)!

    if (lab.default_platform) {
      this.platform = lab.default_platform
    }
    if (lab.default_kit) {
      this.kit = lab.default_kit
    }
    if (lab.default_metagenomic_database) {
      this.metagenomicDatabase = lab.default_metagenomic_database
    }
    if (lab.default_sample_type) {
      this.sampleType = lab.default_sample_type
    }
    if (lab.default_molecule_type) {
      this.moleculeType = lab.default_molecule_type
    }
  }

  private getLabEnforcedOptions() {
    if (!this.labAssociation) {
      return {
        platform: false,
        kit: false,
        metagenomicDatabase: false,
        sampleType: false,
        moleculeType: false,
      }
    }

    const lab = this.labs.find(l => l.id === this.labAssociation)!
    return {
      platform: lab.default_platform_enforced,
      kit: lab.default_kit_enforced,
      metagenomicDatabase: lab.default_metagenomic_database_enforced,
      sampleType: lab.default_sample_type_enforced,
      moleculeType: lab.default_molecule_type_enforced,
    }
  }

  public getFailedUploads() {
    const failed: Upload[] = (this.uploader.failed as Upload[]).concat(
      this.basespaceSampleUploader.failed,
    );
    return failed;
  }

  public getInProgressUploads() {
    const inProgress: Upload[] = (this.uploader.inProgress as Upload[]).concat(
      this.basespaceSampleUploader.inProgress,
    );
    return inProgress;
  }

  public getSucceededUploads() {
    const succeeded: Upload[] = (this.uploader.succeeded as Upload[]).concat(
      this.basespaceSampleUploader.succeeded,
    );
    return succeeded;
  }

  public getStage(key) {
    if (this.archived_publication_analysis) {
      return this.academicStages.indexOf(key) + 1;
    }
    return this.stages.indexOf(key) + 1;
  }

  public selectPlatform(platform: components["schemas"]["Platform"]) {
    this.kit = null;
    const kitsForPlatform = kits.get(platform);
    if (kitsForPlatform) {
      this.kit = kitsForPlatform[0].value;
    }
  }

  private validatePairedReads(): string | undefined {
    const countBySample = this.uploader.succeeded
      .map(s => s.fileMeta.name)
      .map(getIlluminaSampleName)
      .filter(s => s !== null)
      .reduce((counts, sname) => {
        const count = counts.get(sname) || 0
        counts.set(sname, count + 1)
        return counts
      }, new Map())

    for (const [sname, count] of countBySample.entries()) {
      if (count % 2 === 1) {
        return `Expected even number of files for paired-end file group, found ${count} file(s) for ${sname}. Did you forget the other paired read files?`
      }
    }
  }

  private validateSingleReads(): string | undefined {
    const countBySample = this.uploader.succeeded
      .map(s => s.fileMeta.name)
      .map(getIlluminaSampleName)
      .filter(s => s !== null)
      .reduce((counts, sname) => {
        const count = counts.get(sname) || 0
        counts.set(sname, count + 1)
        return counts
      }, new Map())

    for (const [sname, count] of countBySample.entries()) {
      if (count >= 2) {
        return `Found multiple files for ${sname}. Did you mean to select paired end?`
      }
    }
  }

  private validateIlluminaFiles(): string | undefined {
    const invalidFiles = this.uploader.succeeded
      .filter(s => getIlluminaSampleName(s.fileMeta.name) === null)
      .map(s => s.fileMeta.name)
    if (invalidFiles.length > 0) {
      return `The following files do not conform to Illumina filenaming conventions, please see our docs (https://docs.bugseq.com/input/#format) for more detailed information on filename formatting requirements: ${invalidFiles.slice(0, 5).join(", ")}`
    }
  }

  public validateUploads() {
    if (this.uploader.inProgress.length > 0) {
      return;
    }

    if (this.basespaceSampleUploader.inProgress.length > 0) {
      return;
    }

    if (this.platform === "ILLUMINA") {
      const filenameError = this.validateIlluminaFiles()
      if (filenameError) {
        return filenameError;
      }

      const pairedError = this.validatePairedReads()
      if (pairedError) {
        return pairedError;
      }
    } else if (this.platform === "ILLUMINA_SINGLE_END") {
      const singleError = this.validateSingleReads()
      if (singleError) {
        return singleError;
      }
    }

    return;
  }

  public validateMetadata(): string | undefined {
    const sampleNames = this.sampleNames()

    // if we cant parse sample names, no point validating. accept any metadata.
    if (sampleNames.size === 0) {
      return
    }

    const metadataSampleNames = this.metadataRows.map(r => r.sample)
    for (const metadataSampleName of metadataSampleNames) {
      if (!sampleNames.has(metadataSampleName)) {
        return `Unrecognized metadata sample ${metadataSampleName}. Known samples: ${Array.from(sampleNames).sort()}`
      }
    }
  }

  public uploadWarnings() {
    if (this.uploader.inProgress.length > 0) {
      return;
    }

    if (this.basespaceSampleUploader.inProgress.length > 0) {
      return;
    }

    if (this.uploader.succeeded.length === 1) {
      return "It looks like you are uploading a single file. If you are planning to analyze multiple samples or files, submit them all together in a single analysis for faster analysis and better results. BugSeq will automatically demultiplex and handle samples separately.";
    }

    // trial
    if (this.archived_publication_analysis) {
      const sum = this.uploader.succeeded.reduce((partialSum, a) => partialSum + a.fileMeta.size, 0);
      const warningThreshold = 6 * 1024 * 1024;
      if (sum > warningThreshold) {
        return "Trial analyses are limited to 10 gigabases and these files look quite large. Please ensure your files are within the limit for the analysis to succeed."
      }
    }

    return;
  }

  public sampleNames(): Set<string> {
    let sampleNames: string[] = []

    if (this.platform === "ILLUMINA") {
      sampleNames = this.uploader.succeeded.map(f => f.fileMeta.name).map(getIlluminaSampleName).filter(sn => sn !== null)
    } else if (this.platform === "ILLUMINA_SINGLE_END") {
      sampleNames = this.uploader.succeeded.map(f => getIlluminaSampleName(f.fileMeta.name) || removeExtensions(f.fileMeta.name))
    }

    sampleNames = sampleNames.concat(this.basespaceSampleUploader.succeeded.map(bs => bs.basespaceSampleName))
    return new Set(sampleNames)
  }

  public labMetadataKeys(): Set<string> {
    if (!this.labAssociation) {
      return new Set()
    }

    const lab = this.labs.find(l => l.id === this.labAssociation)
    if (!lab) {
      return new Set()
    }

    return new Set(lab.metadata_keys)
  }

  public addMetadataSamples(payload: MetadataRow[]) {
    this.metadataRows = this.metadataRows.concat(payload)
  }

  public removeMetadataSamples(sampleNames: Set<string>) {
    this.metadataRows = this.metadataRows.filter(r => !sampleNames.has(r.sample))
  }

  public redirectToSignUp() {
    this.$router.push("/register")
  }

  public async submit() {
    // nice hack to deal with https://github.com/vuetifyjs/vuetify/issues/3424
    // without it, going from combobox to submit doesn't save the value
    if (this.$refs.sampleTypeCombobox) {
      (this.$refs.sampleTypeCombobox as HTMLElement).blur();
    }
    if (this.$refs.platformCombobox) {
      (this.$refs.platformCombobox as HTMLElement).blur();
    }
    if (this.$refs.kitCombobox) {
      (this.$refs.kitCombobox as HTMLElement).blur();
    }
    await this.$nextTick();

    if (await this.$validator.validateAll()) {
      try {
        this.submissionInProgress = true

        const payload: any = {
          user_provided_name: this.analysisName,
          file_ids: this.getSucceededUploads().map((s) => s.bugseqId),
          lab_id: this.labAssociation || null,
          aws_region: this.s3Region,
          run_options: {
            platform: this.platform,
            kit: this.kit,
            metagenomic_database: this.metagenomicDatabase,
            sample_type: this.sampleType,
            molecule_type: this.moleculeType,
            include_in_lab_db: this.includeInLabDb,
            filter_animal_reads: this.metagenomicDatabase === "NCBI_NT" ? this.filterAnimalReads : null,
          },
          user_provided_metadata: this.metadataRows.length > 0 ? this.metadataRows : null,
        };
        // error handling is done by the dispatch functions
        // which prop up the error message in the bottom banner
        if (this.archived_publication_analysis) {
          payload.email = this.email;
          await dispatchRunCascadeAcademic(this.$store, payload);
        } else {
          await dispatchRunCascade(this.$store, payload);
        }
      } finally {
        this.submissionInProgress = false;
      }
    }
  }
}
</script>

<style scoped>
.code {
  font: 12px Monaco, "Courier New", "DejaVu Sans Mono",
    "Bitstream Vera Sans Mono", monospace;
  border-radius: 3px;
  background-clip: padding-box;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  padding: 0px 3px;
  display: inline-block;
  margin: 0px;
}

.combo-box-field {
  max-width: 30em;
}

.border-radius {
  border-radius: 8px;
}
</style>
