<template>
  <v-data-table
    :headers="headers"
    :items="clusterGrowthRecords"
    class="elevation-1"
    hide-actions
  >
    <template v-slot:items="props">
      <td>
        <router-link
          :to="{ name: insightsRoute, query: { genome_name: props.item.genome_name } }"
        >{{ props.item.genome_name }}</router-link></td>
      <td>
        <router-link
          :to="{ name: insightsRoute, query: { genome_name: props.item.genome_name, cluster_address_lab: props.item.cluster_address_lab_sp.join('.') } }"
        >
          {{ formatClusteringAddress(props.item.genome_name, props.item.cluster_address_lab_sp) }}
        </router-link>
      </td>
      <td>{{ props.item.count }}</td>
      <td>
        <v-chip
          v-if="props.item.implicated_labs && props.item.implicated_labs > 1"
          class="ml-3"
          color="orange lighten-4"
        >
          <v-avatar>
            <v-icon>error</v-icon>
          </v-avatar>
          {{ props.item.implicated_labs }}-lab Outbreak
        </v-chip>
      </td>
    </template>
  </v-data-table>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";
import { formatClusteringAddress } from "@bugseq-site/shared/src/lib/utils";

const ComponentProps = Vue.extend({
  props: {
    clusterGrowthRecords: Array,
    insightsRoute: String,
  },
});

@Component({
  methods: { formatClusteringAddress }
})
export default class ClusterGrowthTable extends ComponentProps {
  private headers = [
    {
      text: 'Genome',
      align: 'left',
      sortable: false,
      value: 'genome_name'
    },
    {
      text: 'Cluster',
      sortable: false,
      value: 'cluster_address_lab_sp',
    },
    {
      text: 'Count',
      sortable: false,
      value: 'count',
    },
    {
      text: '',
      sortable: false,
      value: 'implicated_labs',
      width: '1%',
    },
  ]
}
</script>

<style scoped>
</style>
